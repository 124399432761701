<template>
  <div class="about">
    <div class="top-box desktop">
      <div class="img-box">
        <img
          v-lazy="`${baseUrl}/proxy/${newsInfo.imageLink}`"
          loading="lazy"
        />
         <!-- <img
          v-lazy="`http://35.201.131.54:3001/proxy/${newsInfo.imageLink}`"
          loading="lazy"
        /> -->
      </div>
      <div class="detail-info">
        <div class="title-box">
          <div class="mediaName">
            {{ newsInfo.mediaName }}
          </div>
          <div class="date">{{ creatTime }}</div>
          <div class="author">by {{ newsInfo.author }}</div>
          <div class="line-dot">
            <div class="line"></div>
            <div class="dot"></div>
          </div>
        </div>
        <div class="newsTitle">{{ newsInfo.title }}</div>
        <div class="tags-box">
          <div class="tags" v-for="(tag, index) in newsInfo.tags" :key="index">
            <router-link :to="`/rank/${tag.tagId}`">
              #{{ tag.tag }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="content-box desktop">
      <div v-html="content"></div>
      <div class="read-more-box">
        <div>
          <a
            :href="this.newsInfo.newsLink"
            target="_blank"
            rel="noreferrer noopener"
            class="read-more cursor-pointer"
            >Read more ...</a
          >
        </div>
      </div>
    </div>
    <!-- 992尺寸以下 -->
    <div class="phone-top-box">
      <div class="detail-info">
        <div class="title-box">
          <div class="mediaName">
            {{ newsInfo.mediaName }}
          </div>
          <div class="date">{{ creatTime }}</div>
          <div class="author d-none d-xl-flex">by {{ newsInfo.author }}</div>
          <div class="line-dot d-none d-xl-flex">
            <div class="line"></div>
            <div class="dot"></div>
          </div>
        </div>
        <!-- 768尺寸以下的lind-dot -->
        <div class="d-flex ml-3 mt-2">
          <div class="author">by {{ newsInfo.author }}</div>
          <div class="line-dot">
            <div class="line" style="width:90%"></div>
            <div class="dot"></div>
          </div>
        </div>
        <!-- 768尺寸以下的lind-dot -->
        <div class="newsTitle">{{ newsInfo.title }}</div>
      </div>
      <div class="img-box">
        <img
          v-lazy="`${baseUrl}/proxy/${newsInfo.imageLink}`"
          loading="lazy"
        />
      </div>
      <div class="tags-box">
        <div class="tags" v-for="(tag, index) in newsInfo.tags" :key="index">
          <router-link :to="`/rank/${tag.tagId}`"> #{{ tag.tag }}</router-link>
        </div>
      </div>
      <div class="content-box mt-5">
        <div v-html="content"></div>
        <div class="read-more-box">
          <div>
            <a
              :href="this.newsInfo.newsLink"
              target="_blank"
              rel="noreferrer noopener"
              class="read-more cursor-pointer"
              >Read more ...</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsByID, apiBaseUrl } from "@/axios/app.js";
import * as DateTime from "@/utlis/DateTime";

export default {
  data() {
    return {
      newsInfo: null,
      baseUrl: apiBaseUrl
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    creatTime() {
      let ans = DateTime.format(
        DateTime.toJavaScriptTime(this.newsInfo.postAt),
        "yyyy/MM/DD"
      );
      return ans;
    },
    titleSort() {
      let ans = this.newsInfo.title.substr(0, 20) + "...";
      return ans;
    },
    content() {
      var replaceContent = this.newsInfo.content
        .replace(/^\s+/g, " ")
        .replace(/\n/g, "\n \n");
      return `<div class="content">${replaceContent}</div>`;
    },
  },
  created() {
    getNewsByID(this.id).then((r) => {
      this.newsInfo = r.data.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.about {
  margin-top: 50px;
}
.desktop{
  display: flex;
}
.phone-top-box{
  display: none;
}
.top-box {
  display: flex;
  margin-top: 24px;
  margin-bottom: 50px;
  margin-left: 64px;
  margin-right: 64px;
}

.img-box {
  min-width: 600px;
  max-width: 746px;
  min-height: 422px;
  max-height: 422px;
  border-radius: 15px;
  background: black;
  img {
    object-fit: cover;
    border-radius: 15px;
    width: 100%;
    height: 100%;
  }
}

.detail-info {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title-box {
  display: flex;
  align-items: center;
}

.tags-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-top: auto;
  .tags {
    margin-left: 20px;

    font-family: Tahoma;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    a {
      text-decoration: none;
      color: #7c7c84;
      &:hover {
        color: #cdbbff;
      }
    }
  }
}

.mediaName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: auto;
  padding: 4px 16px;
  margin-left: 32px;
  height: 27px;
  background: #dfdfdf;
  border-radius: 8px 0px 0px 8px;

  font-family: "Inter";
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  white-space: nowrap;
  color: #303030;
}

.date {
  display: flex;
  height: 27px;
  padding-left: 16px;
  padding-right: 32px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  color: #dfdfdf;
  border: 1px solid #dfdfdf;
  border-radius: 0px 8px 8px 0px;
  border-left: none;

  align-items: center;
}

.newsTitle {
  margin-top: 24px;
  margin-left: 32px;
  margin-bottom: 24px;
  text-align: left;

  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.02em;
  color: #dfdfdf;
}

.author {
  margin-left: 18px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;

  color: #dfdfdf;
}
.line-dot {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  .line {
    width: 100%;
    height: 1px;
    margin-left: 20px;
    background-color: #dfdfdf;
  }
  .dot {
    height: 3px;
    width: 3px;
    background-color: #dfdfdf;
    transform: rotate(-45deg);
  }
}

.content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  margin: 0 64px 0 64px;
  white-space: pre-line;

  background: linear-gradient(
    179.82deg,
    #505050 0.15%,
    #3a3a3a 45.86%,
    #2c2c2c 114.13%
  );
  box-shadow: 10px 10px 10px rgba(37, 36, 44, 0.4);
  border-radius: 15px;
  ::v-deep .content {
    color: #d5d5d5;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.5px;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10; //行數
    -webkit-box-orient: vertical;
    white-space: pre-line;
  }
}

.read-more-box {
  width: 100%;
  margin-right: 5px;
  bottom: 36px;
  .read-more {
    float: right;
    text-decoration: underline;
    margin: 10px 10px 0 0;
    color: #a9e1d4;
    font-size: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
  }
}
@media (max-width: 1440px) {
  .mediaName {
  font-size: 14px;
}
.date {
  font-size: 14px;
}
.newsTitle {
  font-size: 28x;
}
.author {
  margin-left: 13px;
}
.content-box {
  ::v-deep .content {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
  .top-box {
    margin-top: 24px;
    margin-bottom: 50px;
    margin-left: 64px;
    margin-right: 64px;
  }
  .img-box {
  min-width: 300px;
  max-width: 506px;
  min-height: 348px;
  max-height: 348px;
  border-radius: 15px;
  margin-left: 1.5rem;
}
  .img-box {
    width: 100%;
    margin-left: 0px;
  }
  .content-box {
    width: 90%;
    margin: 0px 64px 0px 64px;
  }
  .line-dot {
    .line {
      width: 90%;
    }
  }
}
@media (max-width: 1199px) {
  .img-box {
  min-width: 300px;
  min-height: unset;
  max-height: unset;
  min-height: unset;
  border-radius: 15px;
  margin-left: 0px;
}
  .img-box {
    width: 90%;
    height: 365px;
    margin-left: 0px;
  } 
}
@media (max-width: 992px) {
.mediaName {
  font-size: 14px;
}
.date {
  font-size: 14px;
}
.newsTitle {
  font-size: 28x;
}
.author {
  margin-left: 13px;
}
.img-box {
  max-width: 645px;
    margin: auto;
}
.content-box {
    width: 91%;
    margin: auto;
    /* margin: auto; */
  ::v-deep .content {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
}
  .desktop{
    display: none;
  }
  .phone-top-box{
    display: unset;
  margin-top: 24px;
  margin-bottom: 50px;
  margin-left: 32px;
  margin-right: 63px;
  }
}
@media (max-width: 768px) {
  .img-box {
  min-width: unset;
  max-width: unset;
  min-height: unset;
  max-height: unset;
  min-height: unset;
  border-radius: 15px;
  margin-left: auto;
}
  .img-box {
    width: 90%;
    height: 365px;
  } 
}
@media (max-width: 575px) {
  .img-box {
  min-width: 90%;
  height: 274px;
  border-radius: 15px;
  background: black;
  img {
    object-fit: cover;
    border-radius: 15px;
    width: 90%;
    height: 274px;
  }
}
}
</style>
