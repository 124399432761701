<template>
  <VChart
    class="chart"
    :loading="loading"
    :loading-options="ddd"
    autoresize
    :option="option"
  />
</template>

<script>
// eslint-disable-next-line no-unused-vars
var echarts = require("echarts");
import VChart from "vue-echarts";
export default {
  components: {
    VChart,
  },
  name: "Chart",
  props: {
    option: Object,
    loading: Boolean,
  },
  data() {
    return {
      ddd: {
        text: "loading",
        color: "#c23531",
        textColor: "#dfdfdf",
        maskColor: "#1E1E1E",
        zlevel: 0,

        // Font size. Available since `v4.8.0`.
        fontSize: 12,
        // Show an animated "spinner" or not. Available since `v4.8.0`.
        showSpinner: true,
        // Radius of the "spinner". Available since `v4.8.0`.
        spinnerRadius: 10,
        // Line width of the "spinner". Available since `v4.8.0`.
        lineWidth: 5,
        // Font thick weight. Available since `v5.0.1`.
        fontWeight: "normal",
        // Font style. Available since `v5.0.1`.
        fontStyle: "normal",
        // Font family. Available since `v5.0.1`.
        fontFamily: "sans-serif",
      },
    };
  },
  //     return {
  //       option: {
  //         color: ["#80FFA5", "#00DDFF", "#37A2FF", "#FF0087", "#FFBF00"],
  //         tooltip: {
  //           trigger: "axis",
  //         },
  //         legend: {
  //           data: ["Line 1", "Line 2", "Line 3", "Line 4", "Line 5"],
  //         },
  //         grid: {
  //           left: "3%",
  //           right: "4%",
  //           bottom: "3%",
  //           containLabel: true,
  //         },
  //         xAxis: [
  //           {
  //             type: "category",
  //             boundaryGap: false,
  //             data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
  //           },
  //         ],
  //         yAxis: [
  //           {
  //             type: "value",
  //           },
  //         ],
  //         series: [
  //           {
  //             name: "Line 1",
  //             type: "line",
  //             smooth: true,
  //             data: [140, 232, 101, 264, 90, 340, 250],
  //           },
  //           {
  //             name: "Line 2",
  //             type: "line",
  //             smooth: true,
  //             data: [120, 282, 111, 234, 220, 340, 310],
  //           },
  //           {
  //             name: "Line 3",
  //             type: "line",
  //             smooth: true,
  //             data: [320, 132, 201, 334, 190, 130, 220],
  //           },
  //           {
  //             name: "Line 4",
  //             type: "line",
  //             smooth: true,
  //             data: [220, 402, 231, 134, 190, 230, 120],
  //           },
  //           {
  //             name: "Line 5",
  //             type: "line",
  //             smooth: true,
  //             data: [220, 302, 181, 234, 210, 290, 150],
  //           },
  //         ],
  //       },
  //     };
  //   },
};
</script>

<style scoped>
.chart {
  height: 500px;
}
@media (max-width:992px) {
  .chart {
  height: 300px;
}
}
</style>
