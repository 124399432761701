/* eslint-disable prettier/prettier */
export function groupBy(obj, prop) {
    return obj.reduce(function (groups, item) {
        const val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
    }, {});
}
export function sortBy(column, revert = 1, column1, revert1 = 1) {
    return function (a, b) {
        if (a[column] < b[column]) return -1 * revert;
        if (a[column] > b[column]) return 1 * revert;
        if (column1 && a[column1] < b[column1]) return -1 * revert1;
        if (column1 && a[column1] > b[column1]) return 1 * revert1;
        return 0;
    }
}

export function ttt() {
    return 666;
}