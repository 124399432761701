<template>
  <div>
    <div class="mb-32">
      <div class="search-body">
        <div class="keyword">{{ keyword }}</div>
        <div class="results-box">
          <div class="results">
            {{ totalCount ? totalCount : 0 }}
            results
          </div>
          <div @click="changeIcon" class="select-box cursor-pointer">
            <div class="select-text">Select Media</div>
            <div>
              <i
                :class="showList ? 'fa-minus' : 'fa-plus'"
                class="fas select-icon"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showList && mediaNameList.length!==undefined&& mediaNameList.length > 0" class="mediaName-box">
      <CheckBox
        :value="mediaName"
        :key="mediaName"
        v-for="mediaName in mediaNameList"
        v-model="mediaNames"
        @click="mediaNameClick()"
        >{{ mediaName }}</CheckBox
      >
    </div>
    <div v-if="newsShowList.length > 0">
      <div class="masonry">
        <div v-for="i in newsShowList" :key="i.id" class="box" ref="box">
          <div class="card-box">
            <router-link :to="`/about/${i.id}`">
              <div :class="'item-box' + randomNumber(3)">
                <div class="img-box">
                  <img
                    v-lazy="`${baseUrl}/proxy/?url=${i.imageLink}`"
                    loading="lazy"
                  />
                </div>
                <div class="title">
                  {{ i.title }}
                </div>
                <div class="content">{{ i.content }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex" style="margin-left: 36px" v-else>
      We couldn't find any results for your search query. Try your search again
      with more specific keywords.
    </div>
  </div>
</template>

<script>
import { apiBaseUrl } from "@/axios/app.js";
import * as Utli from "@/utlis/Utli";
import CheckBox from "@/components/CheckBox.vue";

export default {
  components: {
    CheckBox,
  },
  data() {
    return {
      showList: true,
      mediaNames: [],
      newsShowList: [],
      baseUrl: apiBaseUrl
    };
  },
  props: {
    keyword: String,
    newsList: Array,
    totalCount: Number,
  },
  computed: {
    mediaNameList() {
      if (this.newsList) {
        var ans = Utli.groupBy(this.newsList, "mediaName");
        return Object.keys(ans);
      } else {
        return null;
      }
    },
  },
  mounted() {
    const data = this.$store.state.type;
    data.forEach((e) => {
      this.mediaNames.push(e);
    });
  },
  watch: {
    keyword: function () {
      this.mediaNames = [];
      const data = this.$store.state.type;
      data.forEach((e) => {
        this.mediaNames.push(e);
      });
    },
    "$store.state.type"(){
      this.mediaNames = [];
      const data = this.$store.state.type;
      data.forEach((e) => {
        this.mediaNames.push(e);
      });
    },
    mediaNames: function () {
      this.mediaNameClick();
    },
    newsList: function () {
      this.mediaNameClick();
    },
  },
  methods: {
    changeIcon() {
      this.showList = !this.showList;
    },
    randomNumber(val) {
      return Math.floor(Math.random() * val);
    },
    mediaNameClick() {
      var ans = [];
      if (this.newsList) {
        this.newsList.forEach((e) => {
          var isInArray = this.mediaNames.includes(e.mediaName);
          if (isInArray) {
            ans.push(e);
          }
          if (this.mediaNames.length == 0) {
            ans = this.newsList;
          }
        });
        this.newsShowList = ans;
        this.totalCount = ans.length;
      } else {
        this.newsShowList = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-body {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #646464;
  padding-bottom: 8px;
  margin-right: 64px;
  margin-left: 40px;
  margin-top: 50px;
}

.keyword {
  font-size: 32px;
  line-height: 17px;
  color: #ffffff;
}

.search-title {
  display: flex;
  margin-left: auto;
  justify-content: space-between;
}

.results-box {
  display: flex;
  align-items: center;
  .results {
    display: flex;
    align-items: center;
    font-family: Tahoma;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    height: 13px;
    line-height: 17px;
    padding-right: 20px;
    border-right: 0.5px solid #dfdfdf;
  }

  .select-box {
    display: flex;
    align-items: center;
    margin-left: 15px;
    .select-text {
      margin-right: 50px;

      font-family: Tahoma;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;

      background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .select-icon {
      background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.mediaName-box {
  display: flex;
  flex-wrap: wrap;
  margin: 32px 64px 30px 30px;
  padding-left: 5px;
  padding-bottom: 10px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.233);
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}

.masonry {
  display: grid;
  grid-template-columns: repeat(4, 1fr); // 分為4列
  column-gap: 32px; // 列間距15px
  margin-left: 32px;
  margin-right: 32px;
  .box {
    flex-direction: column;
    justify-content: center;
    
    &.col-3 {
      padding: 0px;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    .item-box0 {
      color: #737373;
      height: 414px;

      background: linear-gradient(
        202.85deg,
        #5a5a5a -3.29%,
        #575757 41.85%,
        #3c3c3c 98.29%
      );

      box-shadow: 10px 10px 10px rgba(37, 36, 44, 0.4);
      border-radius: 15px;

      &.show {
        color: #dfdfdf;
      }

      &:hover {
        color: #dfdfdf;
      }

      .img-box {
        border: black;
        img {
          border-radius: 15px 15px 0 0;
          background: black;
          object-fit: cover;
          margin-bottom: 15px;
          height: 152px;
          width: 100%;
        }
      }

      .bg-black {
        @extend img;
      }

      .title {
        margin: 0 10px 15px 10px;
        text-align: left;

        font-family: "Inter";
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; //行數
        -webkit-box-orient: vertical;
        white-space: normal;
      }
      .content {
        margin: 0 10px 15px 10px;
        text-align: left;
        font-family: "Inter";
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7; //行數
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }

    .item-box1 {
      @extend .item-box0;
      height: 368px;

      .content {
        -webkit-line-clamp: 6; //行數
      }
    }

    .item-box2 {
      @extend .item-box0;
      height: 347px;

      .content {
        -webkit-line-clamp: 5; //行數
      }
    }
  }
}

.card-box {
  margin-bottom: 48px;
}

.content-dark {
  height: 464px;
  color: #5b5b5c;
}

.mb-32 {
  margin-bottom: 32px;
}

@media (min-width: 1920px) {
  .masonry {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1439px) {
  .masonry {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px; // 列間距15px
    margin-left: 32px;
    margin-right: 64px;
  }
}
@media (min-width: 769px) and (max-width: 992px){
  .masonry {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px; // 列間距15px
    margin-left: 48px;
    margin-right: 32px;
    .box {
    width: 100%;
    max-width: 253px;
    display: flex;
    flex-direction: column;
    margin: auto;
    }
  }
}
@media (min-width: 576px) and (max-width: 768px){
  .masonry {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px; // 列間距15px
    margin-left: 44px;
    margin-right: 32px;
    .box {
    width: 100%;
    // max-width: 240px;
    display: flex;
    flex-direction: column;
    }
  }
  .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
      }
      .content {
        font-size: 18px;
        line-height: 25px;
    }
  }
@media (max-width: 575px) {
  .mediaName-box {
  display: flex;
  flex-wrap: wrap;
  margin: 32px 30px 30px 30px;
  padding-left: 5px;
  padding-bottom: 10px;
}

  .masonry {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 32px; // 列間距15px
    margin-left: 28px;
    margin-right: 32px;
     .box {
    width: 100%;
    // max-width: 240px;
    display: flex;
    flex-direction: column;
   }
  }
  .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
      }
      .content {
        font-size: 18px;
        line-height: 25px;
    }
}

</style>