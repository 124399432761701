<template>
  <masonry
  class="searchmasonry"
    :totalCount="totalCount"
    :keyword="keyword"
    :newsList="newsList"
  ></masonry>
</template>

<script>
import { getKeywordSearch } from "@/axios/app.js";
import masonry from "@/components/masonry.vue";
export default {
  components: {
    masonry,
  },
  data() {
    return {
      newsList: null,
      totalCount: 0,
      status: false,
    };
  },
  mounted() {
    const postData = { keyword: this.keyword, selectedMedia: this.$store.state.type};
    getKeywordSearch(postData)
      .then((r) => {
        //this.newsList = r.data.data.news;
        this.newsList = r.data.data.news.filter((item) => {
          return (
            item.title.toUpperCase().indexOf(this.keyword.toUpperCase()) > -1
          );
        });
        //this.totalCount = r.data.data.news.length;
        this.totalCount = this.newsList.length;
        this.status = true;
      })
      .catch(() => {
        this.status = false;
        this.totalCount = 0;
        this.newsList = [];
      });
  },
  methods:{
   
  },
  computed: {
    keyword() {
      return this.$route.params.keyword;
    },
  
  },
  watch: {
    keyword: function () {
      const postData = { keyword: this.keyword, selectedMedia: this.$store.state.type};
      getKeywordSearch(postData)
        .then((r) => {
          //this.newsList = r.data.data.news;
          this.newsList = r.data.data.news.filter((item) => {
            return (
              item.title.toUpperCase().indexOf(this.keyword.toUpperCase()) > -1
            );
          });
          //this.totalCount = r.data.data.news.length;
          this.totalCount = this.newsList.length;
          this.status = true;
        })
        .catch(() => {
          this.status = false;
          this.totalCount = 0;
          this.newsList = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchmasonry{
  height: 1000px;
  overflow: scroll;
}
.search-body {
  display: flex;
  margin-left: 40px;
  margin-top: 50px;
}

.keyword {
  font-size: 32px;
  line-height: 17px;
  color: #ffffff;
}

.seatch-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.results-box {
  display: flex;
  .results {
    display: flex;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-right: 20px;
    border-right: 1px solid #fff;
  }

  .select-box {
    display: flex;
    align-items: center;
    margin-left: 15px;
    .select-text {
      margin-right: 50px;

      font-family: 'Inter';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;

      background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .select-icon {
      background: linear-gradient(92.52deg, #adf1d2 -8.83%, #9ca0dc 73.34%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.mediaName-box {
  margin: 30px 0px 30px 30px;
  padding-bottom: 10px;
  width: calc(100% - 40px);
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.233);
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}

.masonry {
  display: flex;
  flex-direction: row;
  margin-top: 48px;
  margin-left: 40px;
  .box {
    display: flex;
    flex-direction: column;
    flex: 1;
    a {
      &:hover {
        text-decoration: none;
      }
    }
    .item-box0 {
      width: 90%;
      height: 368px;
      margin-right: 32px;
      margin-bottom: 48px;

      background: linear-gradient(
        202.85deg,
        #5a5a5a -3.29%,
        #575757 41.85%,
        #3c3c3c 98.29%
      );

      box-shadow: 10px 10px 10px rgba(37, 36, 44, 0.4);
      border-radius: 15px;

      .img-box {
        border: black;
        img {
          border-radius: 15px 15px 0 0;
          background: black;
          object-fit: contain;
          margin-bottom: 15px;
          height: 152px;
          width: 100%;
        }
      }

      .bg-black {
        @extend img;
      }

      .title {
        margin: 0 10px 15px 10px;
        text-align: left;

        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #dfdfdf;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; //行數
        -webkit-box-orient: vertical;
        white-space: normal;
      }
      .content {
        margin: 0 10px 15px 10px;
        text-align: left;

        font-size: 14px;
        line-height: 17px;

        color: #dfdfdf;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; //行數
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }

    .item-box1 {
      @extend .item-box0;
      height: 414px;
    }

    .item-box2 {
      @extend .item-box0;
      height: 347px;
    }
  }
}

.content-dark {
  color: #5b5b5c;
}
</style>
