<template>
  <div>
    <div class="title-box">
      <div class="keyword">{{ keyword }}</div>
      <div class="times">
        <div
          @click="getUsage(24, 'h', '2')"
          :class="time == '24h' ? 'show' : ''"
          class="time cursor-pointer"
        >
          24H
        </div>
        <div
          @click="getUsage(7, 'd', '24')"
          :class="time == '7d' ? 'show' : ''"
          class="time cursor-pointer"
        >
          7D
        </div>
        <div
          @click="getUsage(1, 'M', '48')"
          :class="time == '1M' ? 'show' : ''"
          class="time cursor-pointer"
        >
          1M
        </div>
        <div
          @click="getUsage(3, 'M', '168')"
          :class="time == '3M' ? 'show' : ''"
          class="time cursor-pointer"
        >
          3M
        </div>
        <div
          @click="getUsage(6, 'M', '288')"
          :class="time == '6M' ? 'show' : ''"
          class="time cursor-pointer"
        >
          6M
        </div>
        <div
          @click="getUsage(1, 'Y', '720')"
          :class="time == '1Y' ? 'show' : ''"
          class="time cursor-pointer"
        >
          1Y
        </div>
      </div>
    </div>
    <div class="chart-bg">
      <mychart chartId="chart" :loading="loading" :option="option"></mychart>
    </div>
    <masonry
      :keyword="''"
      :totalCount="totalCount"
      :newsList="newsList"
    ></masonry>
  </div>
</template>
<script>
import masonry from "@/components/masonry.vue";
import Mychart from "@/components/Mychart.vue";

import { getTagSearch, getContentUsageByTime } from "@/axios/app.js";
import * as DateTime from "@/utlis/DateTime";

export default {
  components: {
    Mychart,
    masonry,
  },
  data() {
    return {
      newsList: null,
      keyword: "",
      time: "24h",
      totalCount: 0,
      loading: false,
      contnetList: [],
      titleList: [],
      xList: [],
      page: 1,
      option: {
        height: "75%",
        color: ["#CDBBFF", "#A9E1B4"],
        tooltip: {
          show: true,
          trigger: "axis",
          textStyle: {
            align: "left",
          },
        },
        legend: {
          right: "4%",
          data: ["Sort by Title", "Sort by Content"],
          textStyle: { color: "#fff", fontSize: 12, align: "left" },
          lineStyle: "none",
          top: "18",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
            offset: 30,
            axisLabel: {
              textStyle: {
                color: "#DFDFDF",
                fontSize: 14,
                fontWeight: "bolder",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              lineStyle: {
                color: "#aaaaaa",
                width: 0.5,
              },
            },
            axisLabel: {
              textStyle: {
                color: "#DFDFDF",
                fontSize: 14,
                fontWeight: "bolder",
              },
            },
          },
        ],
        series: [
          {
            name: "Sort by Title",
            type: "line",
            symbol: "none",
            showSymbol: false,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Sort by Content",
            type: "line",
            symbol: "none",
            showSymbol: false,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
    };
  },
  created() {
    getTagSearch(this.tagid, this.page).then((r) => {
      this.keyword = r.data.data.name;
      this.newsList = r.data.data.news;
      this.totalCount = r.data.data.totalCount;
    });
    this.getUsage(24, "h", "2");
  },
  watch: {
    tagid() {
      getTagSearch(this.tagid, this.page).then((r) => {
        this.keyword = r.data.data.name;
        this.newsList = r.data.data.news;
        this.totalCount = r.data.data.totalCount;
      });
      this.getUsage(24, "h", "2");
    },
  },
  mounted() {
    this.scrollLoad();

    // document.addEventListener("scroll", function () {
    //   var clientHeight = 0;
    //   if (document.body.clientHeight && document.documentElement.clientHeight) {
    //     clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
    //   } else {
    //     clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
    //   }

    //   var visibleBottom =
    //     window.scrollY + document.documentElement.clientHeight;
    //   console.log(visibleBottom)
    //   console.log(clientHeight);
    // })

    // var box = document.getElementsByClassName("item-box");
    // document.addEventListener("scroll", function () {
    //   //滾動條高度+視窗高度 = 可見區底部高度
    //   var visibleBottom =
    //     window.scrollY + document.documentElement.clientHeight / 2;

    //   //可見區顶部高度
    //   var visibleTop = window.scrollY - 1000;
    //   console.log(visibleBottom)
    //   console.log(visibleTop)

    //   console.log(document.documentElement.clientHeight)

    //   for (var i = 0; i < box.length; i++) {
    //     var centerY = box[i].offsetTop;
    //     if (centerY > visibleTop && centerY < visibleBottom) {
    //       console.log(box[i]);
    //       console.log(box[i].classList);
    //       box[i].classList.add("border-red");
    //     } else {
    //       box[i].classList.remove("border-red");
    //     }
    //   }
    // });
  },
  computed: {
    tagid() {
      return this.$route.params.id;
    },
  },
  beforeDestroy() {
    document.removeEventListener("scroll");
  },
  methods: {
    scrollLoad() {
      let isLoading = false;
      window.onscroll = () => {
        // 距離底部300px時加載一次
        let bottomOfWindow =
          document.documentElement.offsetHeight -
            document.documentElement.scrollTop -
            window.innerHeight <=
          300;

        if (bottomOfWindow && isLoading == false) {
          this.page += 1;

          isLoading = true;
          getTagSearch(this.tagid, this.page).then((r) => {
            r.data.data.news.forEach((item) => {
              this.newsList.push(item);
            });
            isLoading = false;
          });
        }
      };
    },
    getUsage(value, unit, interval) {
      this.loading = true;

      this.time = value + unit;
      let eTime = DateTime.toUnixTime(new Date().getTime());
      let sTime = DateTime.toUnixTime(
        new Date(DateTime.add(new Date().getTime(), -value, unit))
      );

      getContentUsageByTime(this.tagid, "content", sTime, eTime, interval).then(
        (r) => {
          this.refactorUsage(r.data.data.data, "content", unit);
        }
      );
      getContentUsageByTime(this.tagid, "title", sTime, eTime, interval).then(
        (r) => {
          this.refactorUsage(r.data.data.data, "title", unit);
        }
      );
    },
    refactorUsage(val, sortType, unit) {
      var array = [];
      var xArray = [];
      val.forEach((e) => {
        array.push(e.mention);
        if (unit == "h") {
          xArray.push(
            DateTime.format(DateTime.toJavaScriptTime(e.endTime), "HH:mm")
          );
        } else if (unit == "d") {
          xArray.push(
            DateTime.format(DateTime.toJavaScriptTime(e.endTime), "MMM DD")
          );
        } else if (unit == "M") {
          xArray.push(
            DateTime.format(DateTime.toJavaScriptTime(e.endTime), "MMM DD")
          );
        } else {
          xArray.push(
            DateTime.format(DateTime.toJavaScriptTime(e.endTime), "MMM")
          );
        }
      });
      if (sortType == "title") {
        this.contnetList = array;
      } else {
        this.titleList = array;
      }
      this.xList = xArray;

      this.option.xAxis[0].data = this.xList;

      this.option.series[0].data = this.titleList;
      this.option.series[1].data = this.contnetList;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-box {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 58px;
  margin-left: 2px;
  margin-top: 2px;
}
.keyword {
  margin-top: 40px;
  height: 30px;

  font-size: 25px;
  font-weight: 400;
  font-family: Tahoma;
  line-height: 29px;
  text-align: left;
  margin-left: 30px;
}

.times {
  display: flex;
  .time {
    display: flex;
    margin-right: 8px;
    justify-content: center;
    align-items: center;

    color: #dfdfdf;
    background: #303030;

    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 4px 10px;

    width: 46px;
    height: 24px;
    line-height: 16px;
    font-weight: bold;
    font-size: 14px;
  }

  .show {
    color: #303030;
    background: #dfdfdf;
  }
}

.chart-bg {
  max-width: 1300px;
  margin: 30px auto 0px auto;
  background: linear-gradient(180deg, #575757 3.65%, #353535 85.42%);
  box-shadow: 10px 10px 10px rgba(37, 36, 44, 0.4);
  border-radius: 15px;
}
@media (max-width:1440px) {
  .chart-bg {
  max-width: 93%;
  margin: 30px auto 0px auto;
}
}
@media (max-width: 575px) {
  .title-box {
    display: block;
    align-items: baseline;
    justify-content: space-between;
    margin-right: 58px;
    margin-left: 2px;
    margin-top: 2px;
  }
  .time {
    margin-top: 20px;
    text-align: left;
    margin-left: 8px;
  }
  .time:nth-child(1) {
    margin-top: 20px;
    text-align: left;
    margin-left: 30px;
  }
  .chart-bg {
    margin-left: 32px;
    margin-right: 32px;
  }
}
</style>
